import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import * as React from 'react';
import { GlobalPokemonStats, PokemonSetStats } from '../../@types';
import { EnhancedTable } from '../EnhancedTable/EnhancedTable';

export interface ResultsDisplayProps {
    replays: string;
}

export const ResultsDisplay: React.FC<ResultsDisplayProps> = ( props ) => {
    const { replays: logs } = props

    // State Objects
    const [replays] = React.useState<string>(props.replays);
    
    let [playerOneName, setPlayerOneName] = React.useState<string>();
    let [playerTwoName, setPlayerTwoName] = React.useState<string>();

    // Map to track pokemon where key is pID+pkmNickname and value is pokemon object
    const [nicknameMap] = React.useState<Map<string, PokemonSetStats>>(new Map<string, PokemonSetStats>);
    // Map to track statuses and their cause. Key is status+target, value is mapKey of source pokemon
    const [statusMap] = React.useState<Map<string, string>>(new Map<string, string>);
    // Map to track side and field effects like weather, stealth rocks..etc. Key is effect+pID, value is source pokemon mapkey
    const [effectMap] = React.useState<Map<string, string>>(new Map<string, string>);

    // Map to track pokemon data over several matches:
    const [globalPokemonMap, setGlobalPokemonMap] = React.useState<Map<string, GlobalPokemonStats>>(new Map<string, GlobalPokemonStats>);

    const [linksParsedAlready] = React.useState<string[]>([]);

    const [displaySecretMessage, setDisplaySecretMessage] = React.useState<boolean>(false);

    // Hooks
    React.useEffect(() => {
        if (replays === "Kyurem-B" || replays === "Kyurem Black" || replays === "Kyurem-Black"
		|| replays === "kyurem-b" || replays === "kyurem black" || replays === "kyurem-black") {
            setDisplaySecretMessage(true);
        }
        else if (replays !== undefined) {
            setDisplaySecretMessage(false);
            replays.split(' ').forEach((r => {
                if (r !== "") {
                    if (!linksParsedAlready.includes(r)) {
                        linksParsedAlready.push(r);
                        console.log('Parsing logs for: ' + r);
                        fetch(r+'.log', {
                            headers: {
                                'Accept': 'application/json'
                            }
                        }).then(response => response.text()).then(text => parseLogs(text))
                    }
                }
            }))
        }
	}, [])

    // Parses logs inputted line by line
    const parseLogs = (logs: string) => {

        // Reset State for match-specific trackers
        nicknameMap.clear();
        statusMap.clear();
        effectMap.clear();
        
        var statusList = ["psn", "tox", "brn", "slp", "par", "frz", "confusion"]; // confusion handled differently
        // Variables used to determine most recent move for source of damage 
        let lastUsedMoveName = "";
        let lastMoveUserMapKey = "";
        let lastMoveUserPID = "";
        let lastMoveUsedLine = "";
        let lastDamageSelfInflict = false;
        let lastDamageFromEffect = false;
        let lastEffectDamageSourceKey = "";

        let readP1 = false;
        let readP2 = false;

        let p1Name = "";
        let p2Name = "";

        let currentTurn: number = 0;
        let previousLine = "";

        var lines = logs.split('\n');
        for (var l = 0; l < lines.length; l++) {
            var currentLine = lines[l];
            var chunks = currentLine.split('|')
            var signifier = chunks[1];

            if (signifier === "j") {    // join event
                if (chunks[2].includes('☆')) {
                    let playerName = chunks[2].substring(1);
                    if (!readP1) {
                        setPlayerOneName(playerName);
                        p1Name = playerName;
                        readP1 = true;
                    } else if(!readP2) {
                        setPlayerTwoName(playerName);
                        p2Name = playerName;
                        readP2 = true;
                    }
                }
            }
            else if (signifier === "turn") {
                //|turn|22
                currentTurn = Number(chunks[2])
            }
            else if (signifier === "poke") {    // pokemon initializer                
                var pID = chunks[2];
                var pkmName = chunks[3].split(',')[0]; // Separate name from gender
                // TODO: assign p1pkm 1-6 and p2pkm 1-6 in case they never switch in
            }
            // |replace|p2b: Fake Peppino|Zoroark-Hisui, F, shiny
            else if (signifier === "replace") { // Zoroark, for now just only tracks post-illusion stats
                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var pID = position.substring(0, 2); // p1 | p2
                var pkmnNickName = chunks[2].split(": ")[1];
                var pkmName = chunks[3].split(',')[0];


            }
            else if (signifier === "switch" || signifier === "drag") { // switch needs to be both initial and mid-match
                // |switch|p1a: Load🐻ingHappiness|Tapu Fini|141/344 par
                // |drag|p2b: gb -hp +sdef|Beedrill, F|287/287
                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var pID = position.substring(0, 2); // p1 | p2
                var pkmnNickName = chunks[2].split(": ")[1];

                var pkmName = chunks[3].split(',')[0];
                var currentHealth = Number(chunks[4].split('/')[0]);
                var maxHealth = Number(chunks[4].split('/')[1].split(' ')[0]); // split twice in case of status

                var mapKey = pID + pkmnNickName;
                // TODO: test with ": " in nickname, try .split(/_(.*)/s)

                // Check if this pokemon has been tracked yet (by playerID and name)
                if (!nicknameMap.has(mapKey)) {

                    var newPokemon: PokemonSetStats = {
                        playerId: pID,
                        playerName: pID === 'p1' ? p1Name : p2Name,

                        pokemonName: pkmName,
                        pokemonNickname: pkmnNickName,

                        moves: new Map<string, number>(),
                        item: '',

                        currentHealth: currentHealth,
                        maxHealth: maxHealth,
                        totalHealed: 0,
                        totalDamageTaken: 0,
                        totalHealthRecovered: 0,
                        totalDamageDealt: 0,
                        totalCrits: 0,
                        totalKills: 0,
                        totalDeaths: 0,
                        statusesCured: 0, // TODO: statuses cured
                        victory: false
                    }

                    // Enter pokemon into the map with nickname as the key
                    nicknameMap.set(mapKey, newPokemon)
                } else {
                    // This pokemon already exists in out map
                    let tempPkm = nicknameMap.get(mapKey);

                    // Check for regenerator healing
                    if (tempPkm && currentHealth > tempPkm.currentHealth) {
                        var healthDiff = currentHealth - tempPkm.currentHealth;
                        tempPkm.totalHealthRecovered += healthDiff; // increment recovered
                        tempPkm.totalHealed += healthDiff; // increment healing
                        tempPkm.currentHealth = currentHealth; // set new currentHealth
                    }
                }
            }
            else if (signifier === "detailschange") {
                // |detailschange|p1a: Mach 4|Latias-Mega, F
                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var pID = position.substring(0, 2); // p1 | p2
                var pkmnNickName = chunks[2].split(": ")[1];
                var transformingPkmMapkey = pID + pkmnNickName;
                var newPokemonSpecies = chunks[3].split(',')[0];

                let transformingPkmn = nicknameMap.get(transformingPkmMapkey);
                if (transformingPkmn !== undefined) {
                    transformingPkmn.pokemonName = newPokemonSpecies
                }
            }
            else if (signifier === "-heal") {
                // |-heal|p1b: Celebi|218/404|[from] item: Mago Berry
                // |-heal|p1b: Seahonse|153/320|[from] item: Leftovers

                // |-heal|p2a: Smeargle|203/314|[from] Grassy Terrain
                
                // |move|p2b: Labrafour|Morning Sun|p2b: Labrafour
                // |-heal|p2b: Labrafour|290/321
                // |move|p2b: Arboliva|Synthesis|p2b: Arboliva
                // |-heal|p2b: Arboliva|359/359 tox
                // |move|p1a: Seahonse|Heal Pulse|p1b: Himsicott
                // |-heal|p1b: Whimsicott|270/324
                // |move|p1a: Comfey|Floral Healing|p1b: Incineroar
                // |-heal|p1b: Incineroar|394/394

                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var pID = position.substring(0, 2); // p1 | p2
                var pkmnNickName = chunks[2].split(": ")[1];
                var mapKey = pID + pkmnNickName;

                var newHealth = Number(chunks[3].split('/')[0]); // health value after the heal
                let healthDiff = 0;
                let targetPkm = nicknameMap.get(mapKey); // Get existing pokemon from map
                if (targetPkm != undefined) { // Not sure when this would happen
                    healthDiff = newHealth - targetPkm.currentHealth;
                    targetPkm.totalHealthRecovered += healthDiff; // increment healing
                    targetPkm.currentHealth = newHealth; // set new currentHealth
                }

                // Determine source if it is not an item
                let sourcePkmnMapKey;
                if (chunks.length === 5 && chunks[4].includes('[from]') && !chunks[4].includes('item')) {
                    let sourceEffectName = chunks[4].substring(7);
                    let sourceEffectKey = pID + sourceEffectName;
                    sourcePkmnMapKey = effectMap.get(sourceEffectKey);
                } else if (chunks.length === 4) {
                    // healing from move like heal pulse, synthesis, etc
                    sourcePkmnMapKey = lastMoveUserMapKey;
                }
                if (sourcePkmnMapKey !== undefined) {
                    let sourcePkmn = nicknameMap.get(sourcePkmnMapKey);
                    sourcePkmn!.totalHealed += healthDiff;
                }

            }
            else if (signifier === "-crit") {
                // |move|p1a: Fortissax|Thunderbolt|p2a: Revavroom
                // |-crit|p2a: Revavroom

                // Determine pokemon that was crit for TODO: timescrit
                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var pID = position.substring(0, 2); // p1 | p2
                var pkmnNickName = chunks[2].split(": ")[1];
                var mapKey = pID + pkmnNickName;

                let critSourceKey = lastMoveUserMapKey;
                
                if (critSourceKey !== undefined) {
                    let sourcePkmn = nicknameMap.get(critSourceKey);
                    sourcePkmn!.totalCrits++;
                }

            }
            else if (signifier === "-damage") {
                // https://replay.pokemonshowdown.com/gen9doublescustomgame-1833451694.log
                // |-damage|p2b: Thundurus|116/299|[from] item: Life Orb
                // |-damage|p2a: Greninja|284/348|[from] item: Sticky Barb
                // |-damage|p1a: Cake Pop $5.99|123/247|[from] mindblown
                // |-damage|p2a: 156.33.241.5|154/318|[from] steelbeam
                // |-damage|p2a: Smeargle|118/314|[from] highjumpkick
                // |-damage|p2b: Labrafour|191/321|[from] Recoil
                // |-damage|p2b: Toxiq|141/304|[from] recoil
                // |move|p1b: Ribbing|Belly Drum|p1b: Ribbing
                // |-damage|p1b: Ribbing|201/401

                // Todo: the following move hits everyone on the field, but only lists the non-protecting targets as targets
                // |move|p1a: Smeargle|Brutal Swing|p2a: Smeargle|[spread] p1b
                // |-activate|p2a: Smeargle|move: Protect
                // |-damage|p1a: Smeargle|42/314|[from] Spiky Shield|[of] p2a: Smeargle
                // |-activate|p2b: Smeargle|move: Protect
                // |-damage|p1a: Smeargle|3/314|[from] Spiky Shield|[of] p2b: Smeargle

                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var targetPID = position.substring(0, 2); // p1 | p2
                var pkmnNickName = chunks[2].split(": ")[1];
                var targetMapKey = targetPID + pkmnNickName; // ex: 'p1Tapu Fini'

                // |-damage|p2b: gb -hp +sdef|216/287|[from] Stealth Rock

                // If the damage is self inflicted, ignore it in calculating damage done stat (for now)
                lastDamageSelfInflict = (chunks.length > 4 && 
                    (chunks[4].includes("Recoil") || chunks[4].includes("recoil") 
                    || chunks[4].includes("Life Orb") || chunks[4].includes("Black Sludge") 
                    || chunks[4].includes("Sticky Barb")
                    || chunks[4].includes("highjumpkick")
                    || chunks[4].includes("mindblown") || chunks[4].includes("steelbeam")))
                
                // If the damage is from an effect like stealth rock, status, trapping move..etc its handled differently
                lastDamageFromEffect = (!lastDamageSelfInflict && chunks.length > 4 
                    && chunks[4].includes("[from]") && !chunks[4].includes("pokemon: Mimikyu-Busted"));

                let newHealth = chunks[3].includes("fnt") ? 0 : Number(chunks[3].split('/')[0]);

                let targetPkm = nicknameMap.get(targetMapKey); // Get existing pokemon from map
                if (targetPkm != undefined) { // Not sure when this would happen where damage happens first
                    // Calculate damage done based on pokemon's previous known health
                    var healthDiff = targetPkm.currentHealth - newHealth;

                    // Increment their damage taken stat and update their current health
                    targetPkm.totalDamageTaken += healthDiff;
                    targetPkm.currentHealth = newHealth;
                    
                    // Access attacker pkm obj and increment damage done
                    let attackingPkm = nicknameMap.get(lastMoveUserMapKey);
                    if (!(lastDamageSelfInflict || lastDamageFromEffect) 
                        && targetPID != lastMoveUserPID //does not include self-done damage
                        && attackingPkm != undefined) {
                        attackingPkm.totalDamageDealt += healthDiff
                    } else if (lastDamageFromEffect) {
                        // |-damage|p2a: Arceus_Steel|370/394 tox|[from] psn
                        // |-damage|p1b: Pincurchin|263/300 psn|[from] psn
                        // |-damage|p1b: Grapefruit|359/382|[from] Stealth Rock
                        // |-damage|p1b: nickname |417/444|[from] Sandstorm
                        let sourceMapKey;
                        if (chunks.length < 6) {
                            let effectName = chunks[4].substring(7)
                            // check if effect is status
                            if (statusList.includes(effectName)) {
                                let statusKey = targetMapKey + effectName;
                                sourceMapKey = statusMap.get(statusKey)
                            } else {
                                sourceMapKey = effectMap.get(targetPID+effectName);
                            }
                        } else {
                            // |-damage|p1a: Draw +4|155/386|[from] Leech Seed|[of] p2a: Ofourgrowth
                            // |-damage|p1b: shuckle|131/244 tox|[from] ability: Iron Barbs|[of] p2a: ferrothorn
                            // |-damage|p1a: Zapdos|268/321|[from] item: Rocky Helmet|[of] p2b: Toxapex
                            // |-damage|p2a: Blaziken|275/314|[from] Spiky Shield|[of] p1a: Smeargle
                            let effectName = chunks[4].substring(7);
                            let sourcePid = chunks[5].substring(5, 7);
                            let sourceNickname = chunks[5].substring(10);
                            sourceMapKey = sourcePid + sourceNickname;
                        }
                        if (sourceMapKey != undefined) {
                            lastEffectDamageSourceKey = sourceMapKey;
                            let effectSourcePokemon = nicknameMap.get(sourceMapKey);
                            if (targetPID !== sourceMapKey.substring(0,2) 
                                && effectSourcePokemon !== undefined) { // We don't count damage if it was done by teammate or self
                                effectSourcePokemon.totalDamageDealt += healthDiff;
                            }
                        } else {
                            console.log("Effect source undefined: " + sourceMapKey);
                            console.log(currentLine);
                        }
                    }
                } else {
                    console.log("Turn" + currentTurn + ": target pokemon is undefined: " + currentLine);
                    console.log("TargetMapKey = " + targetMapKey)
                }
            }
            else if (signifier === "move") {
                //store move line as most recent move
                // damage and other effect lines can be associated
                // with the most recent move to track who dealt damage, healing etc...

                // 1    2                        3           4             5
                //|move|p2b: The Shrieking Eels|Discharge|p1a: Charles|[spread] p2a,p1a
                //|move|p1b: So no tread?|Earthquake|p2a: Teambuilding Car|[spread] p1a,p2a,p2b

                //|move|p1b: AppreciateIndies|Tailwind|p1b: AppreciateIndies|[from]Snatch
                //|move|p1a: Charles|Tailwind|p1a: Charles
                //|move|p1b: Martha|Protect|p1b: Martha
                //|move|p2a: Smeargle|High Jump Kick|p1a: Smeargle

                // TODO: Handle Snatch
                // TODO: handle Spread move targets

                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var pID = position.substring(0, 2); // p1 | p2
                var moveUserNickname = chunks[2].split(": ")[1];
                lastMoveUserMapKey = pID + moveUserNickname;
                lastMoveUserPID = pID;
                lastMoveUsedLine = currentLine;

                // Record move used in user's object
                lastUsedMoveName = chunks[3]
                let moveUser = nicknameMap.get(lastMoveUserMapKey);
                if (moveUser != undefined) {
                    if (moveUser.moves.has(lastUsedMoveName)) {
                        let prevTimesUsed = moveUser.moves.get(lastUsedMoveName)
                        if (prevTimesUsed === undefined) {
                            prevTimesUsed = 0; //shouldnt happen
                        }
                        moveUser.moves.set(lastUsedMoveName, (prevTimesUsed+1));
                    } else {
                        moveUser.moves.set(lastUsedMoveName, 1);
                    }
                }
            }
            else if (signifier === "-sidestart") {
                // https://replay.pokemonshowdown.com/gen9doublescustomgame-1833330442.log
                // |-sidestart|p1: DiggoryTDD|move: Tailwind
                // |-sidestart|p1: DiggoryTDD|move: Toxic Spikes
                // |-sidestart|p1: DiggoryTDD|move: Light Screen
                // |-sidestart|p1: WhiskeyTattoo|move: Stealth Rock
                // |-sidestart|p2: digg2|Spikes

                // TODO: fix effect name capture for tspikes caused by toxic debris ability 

                var pID = chunks[2].split(":")[0]; //p2 | p1 tells you the side the effect is on
                var effectName = chunks[3].includes("move: ") ? chunks[3].split("move: ")[1] : chunks[3]; // for some reason spikes behaves differently
                var effectKey = pID+effectName;
                effectMap.set(effectKey, lastMoveUserMapKey); // effectMap key is identifier of side+effect, value is the source pokemon
            }
            else if (signifier === "-sideend") {
                // |-sideend|p1: DiggoryTDD|move: Tailwind
                // |-sideend|p2: JuiceTDD|move: Light Screen
                // |-sideend|p1: DiggoryTDD|move: Toxic Spikes|[of] p1a: Toxapex
                var pID = chunks[2].split(" ")[0]; //p2 | p1 tells you the side the effect is on
                var effectName = chunks[3].includes("move: ") ? chunks[3].split("move: ")[1] : chunks[3]; // for some reason spikes behaves differently
                var effectKey = pID+effectName;

                // We don't care why the effect was ended, just need to clear it
                effectMap.delete(effectKey);
            }
            else if (signifier === "-fieldstart") {
                // |-fieldstart|move: Misty Terrain|[from] ability: Misty Surge|[of] p1b: Seahonse
                // |-fieldstart|move: Electric Terrain|[from] ability: Electric Surge|[of] p1b: Pincurchin
                // |-fieldstart|move: Grassy Terrain|[from] ability: Seed Sower|[of] p2b: Arboliva
                // |-fieldstart|move: Trick Room|[of] p2a: Adachi
                // |-fieldstart|move: Wonder Room|[of] p2a: Gee Wiz A Gnarl
                // |-fieldstart|move: Misty Terrain
                // |-fieldstart|move: Gravity

                var fieldEffectName = chunks[2].includes("move: ") ? chunks[2].split("move: ")[1] : chunks[2];

                if (chunks.length === 3) {
                    var sourceMapKey = lastMoveUserMapKey

                } else {
                    var chunkIndexOfSource = (chunks.length > 4) ? 4 : 3; // some fieldstarts like trick room don't have a [from] chunk
                    var sourcePID = chunks[chunkIndexOfSource].substring(5, 7);
                    var sourcePokemon = chunks[chunkIndexOfSource].substring(10);
                    var sourceMapKey = sourcePID + sourcePokemon;
                }
                

                // Add an effect for each side into the effectMap
                effectMap.set(("p1" + fieldEffectName), sourceMapKey); // Map the key=effectName, value=sourcepokemonMapKey
                effectMap.set(("p2" + fieldEffectName), sourceMapKey); // Map the key=effectName, value=sourcepokemonMapKey
            }
            else if (signifier === "-fieldend") {
                // |-fieldend|Misty Terrain
                // |-fieldend|move: Trick Room
                var fieldEffectName = chunks[2].includes("move: ") ? chunks[2].split("move: ")[1] : chunks[2];

                effectMap.delete("p1" + fieldEffectName);
                effectMap.delete("p2" + fieldEffectName);
            }
            else if (signifier === "-weather") {
                // |move|p2b: Smeargle|Sandstorm|p2b: Smeargle
                // |-weather|Sandstorm
                // |move|p1b: Arboliva|Sunny Day|p1b: Arboliva
                // |-weather|SunnyDay

                // |-weather|Sandstorm|[from] ability: Sand Stream|[of] p2a: Miyazaki's Beloved
                // |-weather|SunnyDay|[from] ability: Drought|[of] p1a: Torkoal

                // |-weather|none
                var weatherName = chunks[2];
                if (weatherName !== "none") { // Don't add effect for none
                    let sourceMapKey = "";
                    if (chunks.length > 4) { // likely from switchin ability
                        var sourcePID = chunks[4].substring(5, 7);
                        var sourcePokemon = chunks[4].substring(10);
                        sourceMapKey = sourcePID + sourcePokemon;
                    } else { // Weather from move (last move used)
                        sourceMapKey = lastMoveUserMapKey;
                    }
                    effectMap.set("p1" + weatherName, sourceMapKey); // Will overwrite last use of same weather
                    effectMap.set("p2" + weatherName, sourceMapKey); // Will overwrite last use of same weather
                }
            }
            else if (signifier === "-activate") {
                // |move|p2a: Blaziken|Blaze Kick|p1a: Smeargle
                // |-activate|p1a: Smeargle|move: Protect
                // |-damage|p2a: Blaziken|275/314|[from] Spiky Shield|[of] p1a: Smeargle

                // |move|p1b: Pincurchin|Liquidation|p2b: Toxapex
                // |-activate|p2b: Toxapex|move: Protect
                // |-status|p1b: Pincurchin|psn

                // |move|p2a: Smeargle|High Jump Kick|p1a: Smeargle
                // |-activate|p1a: Smeargle|move: Protect
                // |-damage|p2a: Smeargle|275/314|[from] Spiky Shield|[of] p1a: Smeargle
                
                // ----------------------------- Logic ------------------------------
                // When activate happens, we know the causer of the effect from chunks[2], the name of the effect from chunks[3]
                // and we know the current turn from the variable "current turn"
                // We store an effectMap entry [sourcePkmnName + currentTurn] -> [source pokemon key]

                // In |-status| case, when no [from], when they see that last move was themself, they can look for activate
                // they have the current turn, sourcePkmnName (from lastMoveUsed target)

                // In |-damage| case it tells them from spikey shield directly with sourcPkm key at end
                var sourcePkmnPID = chunks[2].substring(0, 2);
                var sourcePkmnName = chunks[2].substring(5);
                var sourcePkmnKey = sourcePkmnPID + sourcePkmnName;
                effectMap.set(sourcePkmnKey + currentTurn, sourcePkmnKey);
            }
            else if (signifier === "-start") {
                // |move|p2b: Toedscruel|Confuse Ray|p1a: Celebi
                // |-start|p1a: Celebi|confusion

                var statusTargetPID = chunks[2].substring(0, 2);
                var statusTargetName = chunks[2].substring(5);
                var statusTargetMapKey = statusTargetPID + statusTargetName;
                var statusName = chunks[3]

                if (statusName === "confusion") { //TODO: figure out if other -starts are effects too
                    let sourceMapKey = lastMoveUserMapKey;
                    statusMap.set(statusTargetMapKey + statusName, sourceMapKey);
                }
            }
            else if (signifier === "-status") {
                //TODO implement trick item tracking
                //TODO implement confusion and other double statuses if they exist (at least test it works)
                
                // determine status info and target info
                var statusTargetPID = chunks[2].substring(0, 2);
                var statusTargetName = chunks[2].substring(5);
                var statusTargetMapKey = statusTargetPID + statusTargetName;
                var statusName = chunks[3] === "tox" ? "psn" : chunks[3]; // When damage is received from tox it shows up as psn
                let sourceMapKey;

                // determine source info
                if (chunks.length > 4) { // this means there is likely a [from] chunk
                    if (chunks[4].includes("item")) { // |-status|p1a: Zapdos|brn|[from] item: Flame Orb
                        sourceMapKey = statusTargetMapKey;
                    } else if (chunks[4].includes("move")) { // |-status|p1b: Pincurchin|slp|[from] move: Spore
                        sourceMapKey = lastMoveUserMapKey; //TODO: test yawn
                    } else if (chunks.length > 5 && chunks[5].includes("[of] ")) { // |-status|p1b: Bisharp|par|[from] ability: Static|[of] p2a: Zapdos
                        sourceMapKey = chunks[5].substring(5, 7) + chunks[5].substring(10);
                    }
                } else { // status is from secondary chance, -activate effect like baneful bunker, or switchin on hazard
                    // Check if most recent line was switch
                    var previousChunks = previousLine.split('|')
                    if (previousChunks[1] === "switch") { // source is hazard, either 1 or 2 layers of tspikes
                        // look for side effect on [targetPid + effectName] -> [sourceMapKey]
                        // |switch|p1a: Celebi|Celebi|404/404
                        // |-status|p1a: Celebi|psn
                        sourceMapKey = effectMap.get(statusTargetPID + "Toxic Spikes");
                    } else if (lastMoveUserMapKey === statusTargetMapKey) { // target of status just moved, likely hit -activate effect
                        // Determine the target of the lastMoveUsed to get source of the -activate effect
                        // TODO: special logic required for brutal swing and breaking swipe

                        // |move|p2b: Toxapex|Baneful Bunker|p2b: Toxapex
                        // |-singleturn|p2b: Toxapex|move: Protect
                        // |move|p1b: Pincurchin|Liquidation|p2b: Toxapex
                        // |-activate|p2b: Toxapex|move: Protect
                        // |-status|p1b: Pincurchin|psn
                        var recentMoveChunks = lastMoveUsedLine.split('|');
                        var lastMoveTargetPID = recentMoveChunks[4].substring(0,2);
                        var lastMoveTargetNickname = recentMoveChunks[4].substring(5);
                        var lastMoveUsedTargetMapKey = lastMoveTargetPID + lastMoveTargetNickname;
                        if (effectMap.has(lastMoveUsedTargetMapKey + currentTurn)) {
                            sourceMapKey = lastMoveUsedTargetMapKey;
                        } else {
                            sourceMapKey = lastMoveUserMapKey
                        }
                    } else {
                        // last move used was someone else and this is a secondary effect chance (example below)
                        // |move|p2a: Milotic|Scald|p1a: Bisharp
                        // |-damage|p1a: Bisharp|205/322
                        // |-status|p1a: Bisharp|brn
                        sourceMapKey = lastMoveUserMapKey;
                    }
                }
                if (sourceMapKey === undefined) {
                    sourceMapKey = statusTargetMapKey;
                    console.log("DEBUG: unexpected undefined source of status: " +  statusName + " on " + statusTargetMapKey + " on turn: " + currentTurn)
                }
                // Map Key looks like [p1ArbolivaPsn] and value looks like [p2Toxapex]
                statusMap.set(statusTargetMapKey+statusName, sourceMapKey);
            }
            else if (signifier === "-curestatus") {
                // |move|p2a: Celebi|Heal Bell|p2a: Celebi
                // |-activate|p2a: Celebi|move: Heal Bell
                // |-curestatus|p2: Arboliva|tox|[msg]

                // |move|p2b: Celebi|Worry Seed|p1b: Pincurchin
                // |-endability|p1b: Pincurchin|Electric Surge|[from] move: Worry Seed
                // |-ability|p1b: Pincurchin|Insomnia|[from] move: Worry Seed
                // |-curestatus|p1b: Pincurchin|slp|[msg]

                // Sleep cures on its own
                // |-curestatus|p1b: Pincurchin|slp|[msg]

                // |-enditem|p1b: Pincurchin|Lum Berry|[eat]
                // |-curestatus|p1b: Pincurchin|psn|[msg]

                var statusHolderPID = chunks[2].substring(0, 2);
                var statusHolderNickname = chunks[2].split(": ")[1];
                var statusHolderMapKey = statusHolderPID + statusHolderNickname;
                var statusName = chunks[3];
                statusMap.delete(statusHolderMapKey+statusName);
            }
            else if (signifier === "faint") {
                // |-damage|p1b: staraptor|0 fnt|[from] Recoil
                // |faint|p1b: staraptor
                // |faint|p2b: Ronnie Ruins

                // |move|p2a: Smeargle|Memento|p1b: Celebi
                // |faint|p2a: Smeargle

                var position = chunks[2].split(": ")[0]; // p1a | p1b | p2a | p2b
                var fainterPID = position.substring(0, 2); // p1 | p2
                var fainterNickname = chunks[2].split(": ")[1];
                var targetMapKey = fainterPID + fainterNickname;

                // Increment death counter for target
                let faintedPkmn = nicknameMap.get(targetMapKey); 
                if (faintedPkmn != undefined) {
                    faintedPkmn.totalDeaths++;
                }

                // Increment attacker killcount if not from recoil or other effect
                // Access attacker pkm obj and increment damage done
                let attackingPkm = nicknameMap.get(lastMoveUserMapKey);
                if ((targetMapKey !== lastMoveUserMapKey) && !lastDamageFromEffect
                    && fainterPID != lastMoveUserPID // They are not on the same team
                    && attackingPkm != undefined) {
                    attackingPkm.totalKills++
                } else if (lastDamageFromEffect && lastEffectDamageSourceKey !== undefined) {
                    let effectSourcePokemon = nicknameMap.get(lastEffectDamageSourceKey);
                    if (effectSourcePokemon !== undefined && effectSourcePokemon.playerId !== fainterPID) {
                        effectSourcePokemon.totalKills++;
                    }
                }
            }
            else if (signifier === "win") { // |win|DiggoryTDD
                var winnerName = chunks[2];
                let winnerId = (p1Name === winnerName) ? "p1" : "p2";

                // Increment each of the pokemon in map with playerId
                nicknameMap.forEach((pkm) => {
                    if (pkm.playerId === winnerId) {
                        pkm.victory = true;
                    }
                })
            }
            previousLine = currentLine; // used to determine if status is from switchin //TODO: Test if forced switching like Roar, red card, etc triggers a line |switch| (credit should still go to hazard setter)
        }
        // After all lines have been parsed, collect data into global pokemon map:

        registerPokemonDataFromMatch(nicknameMap);

    }

    const registerPokemonDataFromMatch = (matchPkmMap: Map<string, PokemonSetStats>) => {
        matchPkmMap.forEach(pkm => {
            let speciesName = pkm.pokemonName;
            if (globalPokemonMap.has(speciesName)) { // Pokemon already registered with global stats
                let existingPokemon = globalPokemonMap.get(speciesName)
                if (existingPokemon !== undefined) {
                    existingPokemon.playerNames.push(pkm.playerName);
                    existingPokemon.totalPercentTaken += ((pkm.totalDamageTaken / pkm.maxHealth) * 100)
                    existingPokemon.totalDamageTaken += pkm.totalDamageTaken;
                    existingPokemon.totalDamageDealt += pkm.totalDamageDealt;
                    existingPokemon.totalMaxHealth += pkm.maxHealth;
                    existingPokemon.totalHealthRecovered += pkm.totalHealthRecovered;
                    existingPokemon.totalHealed += pkm.totalHealed;
                    existingPokemon.totalKills += pkm.totalKills;
                    existingPokemon.totalCrits += pkm.totalCrits;
                    existingPokemon.totalDeaths += pkm.totalDeaths;
                    existingPokemon.totalGamesPlayed++;
                    pkm.victory ? existingPokemon.totalWins++ 
                                : existingPokemon.totalLosses++;
                }
                globalPokemonMap.set(speciesName, existingPokemon!)
            } else { // New pokemon with no global stats so far
                let newPokemon: GlobalPokemonStats = {
                    pokemonName: speciesName,
                    playerNames: [pkm.playerName],
                    totalPercentTaken: (pkm.totalDamageTaken / pkm.maxHealth) * 100,
                    totalMaxHealth: pkm.maxHealth,
                    totalDamageTaken: pkm.totalDamageTaken,
                    totalDamageDealt: pkm.totalDamageDealt,
                    totalCrits: pkm.totalCrits,
                    totalHealthRecovered: pkm.totalHealthRecovered,
                    totalHealed: pkm.totalHealed,
                    totalKills: pkm.totalKills,
                    totalDeaths: pkm.totalDeaths,
                    totalWins: pkm.victory ? 1 : 0,
                    totalLosses: pkm.victory ? 0 : 1,
                    totalGamesPlayed: 1
                }
                globalPokemonMap.set(speciesName, newPokemon)
            }
        })
        setGlobalPokemonMap(globalPokemonMap)
    }

	return (
        <div>
            {displaySecretMessage ? 
            <div>
                <a href='https://drive.google.com/file/d/15bfiIPKMcNLRKOjMucn4HZ_LZDywqChE/view?usp=sharing'>Well done! (Click me)</a>
            </div> :
            <EnhancedTable
                // data={Array.from(globalPokemonMap.values())}
                data={Array.from(globalPokemonMap.values())}
            />}
        </div>
	)
}