import * as React from 'react';
import { ReplayInput } from '../ReplayInput/ReplayInput'
import { ResultsDisplay } from '../ResultsDisplay/ResultsDisplay';

export const LandingPage: React.FC = ({

}) => {

	// State objects
	const [replayList, setReplayList] = React.useState("");
	const [displayResults, setDisplayResults] = React.useState(false);
 
	// Effect hook for when replayList is updated
	React.useEffect(() => {
		setDisplayResults(replayList !== "")
	}, [replayList])

	return (
		<div>
			<div>
				<ReplayInput fetchLogs={setReplayList}/>
			</div>
			{displayResults && <div>
				<ResultsDisplay replays={replayList}/>
			</div>}
		</div>	
	)
}