import React from 'react';
import './App.css';
import { LandingPage } from './features/LandingPage/LandingPage'
import enemyship from './enemyship.png'

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className='App-logos'>
          <img src={enemyship} alt="enemyship" />
          <img src={enemyship} alt="enemyship" />
          <img src={enemyship} alt="enemyship" />
        </div>
        <LandingPage/>
      </header>
    </div>
  );
}

export default App;
