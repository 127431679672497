import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { GlobalPokemonStats } from '../../@types';
import { TextField } from '@mui/material';

export interface EnhancedTableProps {
    data: GlobalPokemonStats[]
}

type Order = 'asc' | 'desc';

function descendingComparator<GlobalPokemonStats>(a: GlobalPokemonStats, 
    b: GlobalPokemonStats, orderBy: keyof GlobalPokemonStats) {

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function getComparator<Key extends keyof GlobalPokemonStats>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly GlobalPokemonStats[], comparator: (a: GlobalPokemonStats, b: GlobalPokemonStats) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [GlobalPokemonStats, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof GlobalPokemonStats;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'pokemonName',
        numeric: false,
        disablePadding: true,
        label: 'Pokemon',
    },
    {
        id: 'totalKills',
        numeric: true,
        disablePadding: false,
        label: 'Kills',
    },
    {
        id: 'totalDeaths',
        numeric: true,
        disablePadding: false,
        label: 'Deaths',
    },
    {
        id: 'totalWins',
        numeric: true,
        disablePadding: false,
        label: 'Wins',
    },
    {
        id: 'totalLosses',
        numeric: true,
        disablePadding: false,
        label: 'Losses',
    },
    {
        id: 'totalGamesPlayed',
        numeric: true,
        disablePadding: false,
        label: 'Games Played',
    },
    {
        id: 'totalDamageDealt',
        numeric: true,
        disablePadding: false,
        label: 'Dmg Dealt',
    },
    {
        id: 'totalDamageTaken',
        numeric: true,
        disablePadding: false,
        label: 'Dmg Taken',
    },
    {
        id: 'totalPercentTaken',
        numeric: true,
        disablePadding: false,
        label: 'Dmg Taken as %',
    },
    {
        id: 'totalHealthRecovered',
        numeric: true,
        disablePadding: false,
        label: 'HP Recovered',
    },
    {
        id: 'totalHealed',
        numeric: true,
        disablePadding: false,
        label: 'HP Healed',
    },
    {
      id: 'totalCrits',
      numeric: true,
      disablePadding: false,
      label: 'Critical Hits',
  },
];

const DEFAULT_ORDER = 'desc';
const DEFAULT_ORDER_BY = 'totalKills';
const DEFAULT_ROWS_PER_PAGE = 10;

interface EnhancedTableHeadProps {
    //numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof GlobalPokemonStats) => void;
    //onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { /*onSelectAllClick,*/ order, orderBy,/*numSelected,*/ rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (newOrderBy: keyof GlobalPokemonStats) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  filterByName: (name: string) => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, filterByName } = props;

  let [nameFilter, setNameFilter] = React.useState<string>("");

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
        <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
        >
            Pokemon Statistics
        </Typography>
        <TextField
                value={nameFilter}
                label="Filter by trainer:"
                onChange={(e) => {
                    filterByName(e.target.value);
                    setNameFilter(e.target.value);
                }}
            />
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <FilterListIcon /> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export const EnhancedTable: React.FC<EnhancedTableProps> = props => {
    const { data } = props;

    const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState<keyof GlobalPokemonStats>(DEFAULT_ORDER_BY);
    // const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [visibleRows, setVisibleRows] = React.useState<GlobalPokemonStats[] | null>(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [paddingHeight, setPaddingHeight] = React.useState(0);

    let [nameFilter, setNameFilter] = React.useState<string>("");
    let [filteredData, setFilteredData] = React.useState<GlobalPokemonStats[]>(props.data);

    const hasName = (nameArray: string[], name:string) => {
        return nameArray.some(e => e.includes(name))
    }

    React.useEffect(() => {
        if (nameFilter === "") {
            setFilteredData(props.data)
        } else {
            setFilteredData(props.data.filter((row) => hasName(row.playerNames, nameFilter)))
        }
    }, [nameFilter, props.data])

    React.useEffect(() => {
        let rowsOnMount = stableSort(
            filteredData,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );
        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

    setVisibleRows(rowsOnMount);
    }, [filteredData]);

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<unknown>, newOrderBy: keyof GlobalPokemonStats) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = stableSort(filteredData, getComparator(toggledOrder, newOrderBy));
            const updatedRows = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
        );
        setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage, filteredData],
  );

  const handleChangePage = React.useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);

      const sortedRows = stableSort(filteredData, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage,
      );
      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows =
        newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - filteredData.length) : 0;

      const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [order, orderBy, dense, rowsPerPage, filteredData],
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);

      const sortedRows = stableSort(filteredData, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        0 * updatedRowsPerPage,
        0 * updatedRowsPerPage + updatedRowsPerPage,
      );
      setVisibleRows(updatedRows);

      // There is no layout jump to handle on the first page.
      setPaddingHeight(0);
    },
    [order, orderBy],
  );

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const getPlayerNameTooltipText = (names: string[]) => {
    let uniqueNames: string[] = [];
    let uniqueNamesList: string = "";
    names.forEach((n) => {
        if (!uniqueNames.includes(n)) {
          uniqueNames.push(n);
          uniqueNamesList += n + ", "
        }
    });
    return uniqueNamesList.substring(0, uniqueNamesList.length-2)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={filteredData.length} filterByName={setNameFilter}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              //numSelected={rows.length}
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
            />
            <TableBody>
              {visibleRows
                ? visibleRows.map((row, index) => {
                    //const isItemSelected = isSelected(row.pokemonName);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <Tooltip 
                        title={getPlayerNameTooltipText(row.playerNames)} 
                        followCursor 
                        disableInteractive
                      >
                        <TableRow
                          hover
                          //onClick={(event) => handleClick(event, row.pokemonName)}
                          role="checkbox"
                          //aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.pokemonName}
                          //selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell align="right"></TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.pokemonName}
                          </TableCell>
                          <TableCell align="right">{row.totalKills}</TableCell>
                          <TableCell align="right">{row.totalDeaths}</TableCell>
                          <TableCell align="right">{row.totalWins}</TableCell>
                          <TableCell align="right">{row.totalLosses}</TableCell>
                          <TableCell align="right">{row.totalGamesPlayed}</TableCell>
                          <TableCell align="right">{row.totalDamageDealt}</TableCell>
                          <TableCell align="right">{row.totalDamageTaken}</TableCell>
                          <TableCell align="right">{row.totalPercentTaken.toFixed(1)}%</TableCell>
                          <TableCell align="right">{row.totalHealthRecovered}</TableCell>
                          <TableCell align="right">{row.totalHealed}</TableCell>
                          <TableCell align="right">{row.totalCrits}</TableCell>
                        </TableRow>
                      </Tooltip>
                    );
                  })
                : null}
              {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: paddingHeight,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
