import * as React from 'react';
import { TextField, Button } from '@material-ui/core'
import { Stack } from '@mui/material';
import '../../replay_data/season_6_replays.txt'

export interface ReplayInputProps {
    fetchLogs: (logs:string) => void
}

export const ReplayInput: React.FC<ReplayInputProps> = props => {

    const { fetchLogs } = props
    const [linkInput, setLinkInput] = React.useState("");
    
    return (
        <div>
            <h2>
                Pokmon Showdown Data Collector
            </h2>
            <Stack direction="column" spacing={2}>
                <TextField
                    value={linkInput}
                    fullWidth
                    label="Paste Replay Links Here (Space delimited)"
                    onChange={(e) => {
                        setLinkInput(e.target.value);
                    }}
                />
                <div>
                    <Stack direction="row" spacing={2} justifyContent='center'>
                        <Button variant="contained" onClick={() => {
                            fetchLogs(linkInput);
                        }}>Submit</Button>
                        <Button variant="contained" onClick={() => {
                            setLinkInput("");
                            fetchLogs("");
                        }}>Clear</Button>
                    </Stack>
                </div>
            </Stack>
            <br/>
        </div>
    );
}